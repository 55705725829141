<template>
  <ul class="space-y-2 text-center">
    <li
      v-for="item in data"
      :key="item"
      class="px-2 py-1 rounded-full shadow w-72 bg-gray-50"
    >
      {{ item }}
    </li>
  </ul>
</template>

<script>
export default {
  setup() {
    return {
      data: ["Jan Mühlfeit 🇨🇿", "Joe Hackman 🇺🇸", "Radek Hofírek 🇨🇿"],
    };
  },
};
</script>
