<template>
  <teleport to="#modals">
    <transition
      leave-to-class="animate-fade-out"
      enter-active-class="animate-fade-in"
    >
      <div
        v-if="open"
        class="fixed inset-0 z-50 flex items-center justify-center space-x-0 overflow-x-hidden overflow-y-auto bg-black bg-opacity-60"
        @click="$emit('close')"
      >
        <div @click.stop class="p-6 rounded-md shadow bg-gray-50 w-96">
          <div class="flex border-b-2">
            <div class="self-center flex-grow text-lg font-semibold">
              <slot name="header"></slot>
            </div>
            <button
              @click="$emit('close')"
              type="button"
              class="self-start p-1 ml-2 group"
            >
              <XIcon class="icon-sm icon-action" />
            </button>
          </div>
          <div class="mt-2">
            <slot name="content"></slot>
          </div>
          <div class="flex items-center mt-4 space-x-2">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { watchEffect } from "vue";
import { XIcon } from "../icons";

export default {
  components: { XIcon },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["close"],
  setup(props) {
    watchEffect(() => {
      document.body.classList.toggle("overflow-hidden", props.open);
    });
  },
};
</script>
