<template>
  <StreamPageLayout :live="true">
    <template #player>
      <WelcomeMessage
        :open="false"
        @close="showMessage = false"
        :live="settings.live"
      />
      <div ref="videoWrapperRef" class="max-h-screen">
        <VideoPlayer
          v-if="true"
          url="beX9WXqnYxM?start=162"
          :style="{ 'max-width': maxWidth }"
          @load="showMessage = true"
        >
          <div class="absolute z-20 top-5 right-10">
            <Poll />
          </div>
        </VideoPlayer>
        <div v-else class="flex items-center justify-center h-screen">
          <Spinner v-if="loading" class="text-white icon-xl" />
          <Poster v-else-if="!settings.live" />
          <div
            v-else
            class="flex flex-col items-center justify-center space-y-6"
          >
            <BellIcon class="text-white w-14 animate-pulse" />
            <div class="px-4 text-xl text-center text-white md:text-2xl">
              {{ settings.message }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #header>
      <Logo class="hidden w-16 h-16 p-2 bg-red-500 rounded-md lg:block" />
      <div class="lg:ml-4">
        <h1 class="text-lg leading-tight">
          How Covid-19 is changing work life
        </h1>
        <h2 class="text-sm text-gray-500">
          Talk Organized by UCT Students
        </h2>
      </div>
    </template>
    <template #content><Schedule class="mt-4" /> </template>
    <template #sidebar><Chat /></template>
  </StreamPageLayout>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";

import Chat from "../components/Chat";
import Poll from "../components/Poll";
import Schedule from "../components/Schedule";
import Logo from "../components/Logo";
import VideoPlayer from "../components/VideoPlayer";
import WelcomeMessage from "../components/WelcomeMessage";
import StreamPageLayout from "../layouts/StreamPageLayout";
import Poster from "../components/Poster";
import { BellIcon } from "../components/icons";
import Spinner from "../components/shared/Spinner";
import { SUBSCRIBE_SETTINGS } from "../store/actions";

export default {
  components: {
    VideoPlayer,
    Chat,
    Poll,
    Schedule,
    Logo,
    WelcomeMessage,
    StreamPageLayout,
    BellIcon,
    Spinner,
    Poster,
  },

  setup() {
    const store = useStore();
    const videoWrapperRef = ref();
    const maxWidth = ref("");
    const settings = computed(() => store.state.settings);
    const loading = computed(() => store.state.loading.settings);
    const showMessage = ref(false);

    store.dispatch(SUBSCRIBE_SETTINGS);

    const resize = () => {
      const height = videoWrapperRef.value.getBoundingClientRect().height;
      const width = Math.floor((height / 9) * 16);
      maxWidth.value = `${width}px`;
    };
    onMounted(resize);

    return {
      settings,
      maxWidth,
      videoWrapperRef,
      loading,
      showMessage,
    };
  },
};
</script>
