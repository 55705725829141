<template>
  <div class="mx-auto">
    <div className="relative h-0 pb-56.25">
      <slot />
      <iframe
        :src="`https://www.youtube.com/embed/${url}`"
        frameborder="0"
        class="absolute top-0 left-0 w-full h-full"
        @load="$emit('load')"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["url"],
  emits: ["load"],
};
</script>
