<template>
  <div>
    <div class="flex items-center justify-center min-h-screen py-6">
      <div>
        <div class="flex items-center justify-center">
          <Logo class="w-10" />
        </div>
        <PosterTitle />
        <div class="flex justify-center mt-8">
          <PosterSpeakers />
        </div>
        <div class="flex justify-center mt-8 text-white">
          <PosterInfo />
        </div>
        <div
          class="flex flex-col items-center justify-center mt-8 text-sm text-white"
        >
          <PosterLinks />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "../components/Logo";
import PosterTitle from "./PosterTitle";
import PosterInfo from "./PosterInfo";
import PosterLinks from "./PosterLinks";
import PosterSpeakers from "./PosterSpeakers";

export default {
  components: { Logo, PosterTitle, PosterInfo, PosterLinks, PosterSpeakers },
};
</script>
