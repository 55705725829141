<template>
  <form @submit.prevent="handleSubmit">
    <h1 class="text-lg border-b-2">Messages</h1>
    <div
      class="flex items-center px-2 py-1 mt-2 space-x-2 text-xs text-gray-700 bg-gray-100 rounded-md"
    >
      <InfoIcon class="flex-shrink-0 icon-sm" />
      <p>
        Send us your questions here and we will read them out to the speakers
        for you
      </p>
    </div>
    <div class="relative mt-2">
      <textarea
        v-model="message"
        class="w-full px-4 py-2 bg-gray-200 border-none rounded-md resize-none md:h-32 focus:outline-none"
        :maxlength="maxMessageLength"
        placeholder="Type your question or comments"
      />
    </div>
    <div class="mt-2">
      <div class="flex items-center justify-between">
        <label class="input-label" for="name">Name</label>
        <p class="mr-2 italic text-gray-600 text-2xs">Optional</p>
      </div>
      <input
        name="name"
        class="input"
        v-model="name"
        :maxlength="maxNameLength"
      />
    </div>
    <div class="flex items-center mt-2">
      <Button primary submit>Send</Button>
      <Spinner v-if="loading" class="w-6 ml-2 text-red-500" />

      <transition
        leave-to-class="animate-fade-out"
        enter-active-class="animate-fade-in"
      >
        <CheckIcon v-if="messageSent" class="ml-2 text-red-500 icon-sm" />
      </transition>
      <transition
        leave-to-class="animate-fade-out"
        enter-active-class="animate-fade-in"
      >
        <div
          v-if="messageError"
          class="flex items-center space-x-2 text-red-500"
        >
          <ExclamationCircleIcon class="ml-2 icon-sm" />
          <p class="text-xs">An error occurred, try again.</p>
        </div>
      </transition>
    </div>
    <Modal :open="modalOpen" @close="modalOpen = false">
      <template #header
        >Reached character limit of {{ maxMessageLength }} characters
      </template>
      <template #footer>
        <Button primary @click="modalOpen = false">Close</Button>
      </template>
    </Modal>
  </form>
</template>

<script>
import { ref, watch } from "vue";
import axios from "axios";

import Button from "./shared/Button";
import Spinner from "./shared/Spinner";
import Modal from "./shared/Modal";
import { CheckIcon, InfoIcon, ExclamationCircleIcon } from "./icons";

const maxMessageLength = 350;
const maxNameLength = 40;

export default {
  components: {
    Button,
    Spinner,
    Modal,
    CheckIcon,
    InfoIcon,
    ExclamationCircleIcon,
  },
  setup() {
    const message = ref("");
    const name = ref("");
    const messageSent = ref(false);
    const messageError = ref(false);
    const loading = ref(false);
    const modalOpen = ref(false);

    watch(message, () => {
      if (message.value.length >= maxMessageLength) {
        modalOpen.value = true;
      }
    });

    const handleMessageSent = () => {
      message.value = "";
      name.value = "";

      messageError.value = false;
      messageSent.value = true;
      setTimeout(() => {
        messageSent.value = false;
      }, 2000);
    };

    const handleMessageError = () => {
      messageError.value = true;
      setTimeout(() => {
        messageError.value = false;
      }, 2000);
    };

    const handleSubmit = async () => {
      if (
        message.value.length > 0 &&
        message.value.length <= maxMessageLength &&
        name.value.length <= maxNameLength &&
        !messageSent.value
      ) {
        loading.value = true;

        try {
          await axios.post(process.env.VUE_APP_SEND_MESSAGE_URL, {
            text: message.value,
            name: name.value,
          });
          handleMessageSent();
        } catch (e) {
          handleMessageError();
          console.error(e);
        } finally {
          loading.value = false;
        }
      }
    };

    return {
      message,
      handleSubmit,
      name,
      messageSent,
      messageError,
      loading,
      maxMessageLength,
      maxNameLength,
      modalOpen,
    };
  },
};
</script>
