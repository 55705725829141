<template>
  <Modal>
    <template #header>
      Welcome to our event 👋
    </template>
    <template #content>
      <div>
        <ol class="space-y-2 list-decimal list-inside">
          <li v-for="(item, index) in rules" :key="index">
            {{ item }}
          </li>
        </ol>
      </div>
    </template>
    <template #footer>
      <Button primary @click="$emit('close')">Continue</Button>
    </template>
  </Modal>
</template>

<script>
import Modal from "./shared/Modal";
import Button from "./shared/Button";

export default {
  props: {
    live: {
      required: true,
      type: Boolean,
    },
  },
  components: { Modal, Button },
  setup() {
    return {
      rules: [
        "Click the video to start watching 📺",
        "Vote in polls in the upper right corner, different polls will show up during the stream 📊",
        "Ask any questions to our speakers below ⬇️",
      ],
    };
  },
};
</script>
