<template>
  <div class="flex flex-col items-center justify-center mt-4">
    <div class="px-6 text-3xl font-semibold text-center text-white">
      How Covid-19 is changing work life
    </div>
    <div class="mt-2 text-red-300 text-md">
      Talk Organized by UCT Students
    </div>
  </div>
</template>
