<template>
  <ul class="space-y-2">
    <li
      v-for="item in schedule"
      :key="item.time"
      class="flex items-center w-full space-x-4"
    >
      <div class="text-sm text-gray-500 whitespace-nowrap">
        {{ item.time }}
      </div>
      <div
        class="flex items-center w-full px-4 py-2 bg-gray-200 rounded-full hover:bg-opacity-80"
        :class="{ 'bg-green-200': item.speaker, 'bg-yellow-200': item.ad }"
      >
        <div class="flex items-center flex-grow space-x-2">
          <MicrophoneIcon v-if="item.speaker" class="w-4" />
          <div>
            {{ item.title }}
          </div>
        </div>
        <div class="ml-2 text-xs">
          {{ item.duration }}
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { MicrophoneIcon } from "./icons";

export default {
  components: { MicrophoneIcon },
  setup() {
    return {
      schedule: [
        {
          time: "18:00 - 18:25",
          title: "Joe Hackman",
          speaker: true,
          duration: "25m",
        },
        {
          time: "18:25 - 18:30",
          title: "Break",
          duration: "5m",
        },
        {
          time: "18:30 - 18:55",
          title: "Radek Hofírek",
          speaker: true,
          duration: "25m",
        },
        {
          time: "18:55 - 19:00",
          title: "Break",
          duration: "5m",
        },
        {
          time: "19:00 - 19:50",
          title: "Jan Mühlfeit",
          speaker: true,
          duration: "50m",
        },
        {
          time: "19:50 - 19:55",
          title: "Break",
          duration: "5m",
        },
        {
          time: "19:55 - 20:00",
          title: "About School of Business",
          duration: "5m",
          ad: true,
        },
      ],
    };
  },
};
</script>
