<template>
  <div>
    Join our
    <a
      class="underline cursor-pointer"
      href="https://www.facebook.com/events/883472669158466/"
      target="_blank"
      rel="noopener noreferrer"
      >Facebook event</a
    >!
  </div>
  <div class="text-red-300">AND</div>
  <div class="flex flex-col items-center text-center">
    Come back here to watch the stream
  </div>
</template>
