<template>
  <div class="bg-gray-100">
    <div
      :class="
        live ? 'bg-gray-900' : 'bg-gradient-to-br from-red-500 to-red-600'
      "
    >
      <slot name="player"></slot>
    </div>
    <div class="flex flex-col-reverse max-w-5xl pb-16 mx-auto mt-4 md:flex-row">
      <div class="flex-grow px-4 mt-4 md:mt-0">
        <Card class="flex flex-col">
          <div class="flex items-center pb-2 border-b-2">
            <slot name="header"></slot>
          </div>
          <slot name="content"></slot>
        </Card>
      </div>
      <div class="px-4 md:pl-0 lg:px-8 lg:pl-0">
        <Card class="w-full md:w-80">
          <slot name="sidebar"></slot>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "../components/shared/Card";

export default {
  components: { Card },
  props: ["live"],
};
</script>
