<template>
  <ul class="space-y-1">
    <li
      v-for="item in data"
      :key="item.text"
      class="relative flex items-center space-x-2 text-red-100"
    >
      <component :is="item.icon" class="absolute w-6 text-red-400 -left-6" />
      <div>{{ item.text }}</div>
    </li>
  </ul>
</template>

<script>
import {
  CalendarIcon,
  ClockIcon,
  TranslateIcon,
  LocationMarkerIcon,
} from "../components/icons";

export default {
  setup() {
    return {
      data: [
        {
          text: "9.2.2021",
          icon: CalendarIcon,
        },
        {
          text: "18:00 CET",
          icon: ClockIcon,
        },
        {
          text: "Online",
          icon: LocationMarkerIcon,
        },
        {
          text: "English",
          icon: TranslateIcon,
        },
      ],
    };
  },
};
</script>
